import { h, Component } from 'preact';
/** @jsx h */

import User from './firebase/user';

import styles from './signInForm.scss';

const providers = [
  {
    class: styles['google'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg',
    onClick: User.signInWithGoogle,
    name: 'Google',
  },
  {
    name: 'Facebook',
    class: styles['facebook'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg',
    onClick: User.signInWithFacebook,
  },
  {
    name: 'Twitter',
    class: styles['twitter'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg',
    onClick: User.signInWithTwitter,
  },
  // {
  //   name: 'Microsoft',
  //   class: styles['microsoft'],
  //   imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg',
  //   onClick: User.signInWithMicrosoft,
  // },
];

class SignInForm extends Component {
  onRef = ref => {
    SignInForm.ref = ref;
  }

  static signIn = () => {
    SignInForm.ref.showModal();
  }

  render() {
    return (
      <User>
        {user => {
          return !user && (
            <dialog ref={this.onRef} class={styles['sign-in']}>
              <form method="dialog">
                <p>Please sign in to use this site</p>
                <ul>
                  {providers.map(provider => (
                    <li class={provider.class}>
                      <button onclick={provider.onClick}>
                        {provider.imageUrl && (
                          <img src={provider.imageUrl} />
                        )}
                        Sign in with {provider.name}
                      </button>
                    </li>
                  ))}
                </ul>
                <button>Cancel</button>
              </form>
            </dialog>
          );
        }}
      </User>
    );
  }
}

export default SignInForm;
