import { h, Component } from 'preact';
/** @jsx h */

import Topic from './topic';
import SignInForm from './signInForm';

import styles from './checkAvailability.scss';

class CheckAvailability extends Component {
  // TODO: debounce?
  onChange = async () => {
    this.setState({
      isAvailable: null,
    }, async () => {
      if (this.ref && this.ref.value) {
        const isAvailable = await Topic.isAvailable(this.ref.value);
  
        this.setState({
          isAvailable,
        });
      }
    });
  }

  onClaim = async () => {
    await Topic.claimTopic(this.ref.value);

    const url = `//${this.ref.value}.${window.location.host}`;

    window.location.href = url;
  }

  onRef = ref => {
    this.ref = ref;
  }

  render() {
    const { user } = this.props;

    return (
      <section class={styles['check-availability']}>
        <input placeholder="my-game" ref={this.onRef} onkeyup={this.onChange} type="text" />.lookingfor.gg
        <br />
        <br />
        {this.state.isAvailable === true && (
          <div>
            This subdomain is available!
            {user && (
              <button onclick={this.onClaim}>Claim it!</button>
            ) || (
              <button onclick={SignInForm.signIn}>Claim it!</button>
            )}
          </div>
        )}
        {this.state.isAvailable === false && (
          <div>
            This subdomain already exists!  <a href={`//${this.ref.value}.${window.location.host}`}>Check it out</a>
          </div>
        )}
      </section>
    )
  }
}

export default CheckAvailability;
