import { Component } from 'preact';

class PopularTopics extends Component {
  constructor() {
    super();

    this.state = {
      topics: [],
    };
  }

  componentWillMount() {
    this.unsubscribe = firebase.firestore().collection('topics').orderBy('groupCount', 'desc').limit(3).onSnapshot(snapshot => {
      const topics = snapshot.docs.map(doc => {
        const data = doc.data();

        return {
          key: doc.id,
          ...data,
        };
      });

      this.setState({
        topics,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    return this.props.children[0](this.state.topics);
  }
}

export default PopularTopics;
