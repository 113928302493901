import { h, render } from 'preact';
/** @jsx h */

import stata from 'stata';
const { createStore } = stata;

import Navigation from '../src/components/navigation';
import User from '../src/components/firebase/user';
import SignInForm from '../src/components/signInForm';
import CheckAvailability from '../src/components/checkAvailability';

import PopularTopics from '../src/components/firebase/popularTopics';
import TopicSearch from '../src/components/topicSearch';

const config = {
  apiKey: "AIzaSyAFYQekyHNYVdekZYbVJkXPEXFyhACUCyA",
  authDomain: "chat-f90cc.firebaseapp.com",
  databaseURL: "https://chat-f90cc.firebaseio.com",
  projectId: "chat-f90cc",
  storageBucket: "chat-f90cc.appspot.com",
  messagingSenderId: "959061890472"
};

const app = firebase.initializeApp(config);

app.firestore().settings({ timestampsInSnapshots: true});

// firebase.database.enableLogging(true);

import styles from './app.scss';

const store = createStore(
  // Initial state
  {
  },
  // Actions
  {
  },
  // Render method
  (state, actions) => {
    render(
      <User>
        {user => (
          <main id="app">
            {/* <Navigation user={user} /> */}
            <section class={styles['left']}>
              Gamers are looking for groups to play with:<br />
              <PopularTopics>
                {topics => {
                  return (
                    <ul class={styles['popular-topics']}>
                      {topics.map(topic => {
                        const url = `//${topic.key}.${window.location.host}`;

                        return (
                          <li>
                            <span class={styles['count']}>{topic.groupCount} gamers in</span>
                            &nbsp;
                            <a href={url}>{topic.title}</a>
                          </li>
                        );
                      })}
                    </ul>
                  );
                }}
              </PopularTopics>
              <TopicSearch />
            </section>
            {user && (
              <section>
                Thank you for signing in {user.displayName}!<br />
                <br />
                <a href="/help">Learn more about this site</a>
              </section>
            ) || (
              <section>
                Join lookingfor.gg<br />
                <br />
                <button id="sign-in" onclick={SignInForm.signIn}>Sign up or log in</button>
                <SignInForm />
              </section>
            )}
            {/* <h1>lookingfor.gg</h1>
            <p>This is a free service that allows you to set up a place where gamers can find others to play with.</p>
            <p>First, choose a subdomain for your game:</p>
            <CheckAvailability user={user} /> */}
          </main>
        )}
      </User>
    , document.body, document.getElementById('app'));
  }
);
