import { h, Component } from 'preact';
/** @jsx h */

class EditText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  onChange = () => {
    this.setState({
      value: this.ref.value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSave(this.state.value);
  }

  onReset = () => {
    this.setState({
      value: this.props.value,
    }, () => {
      this.ref.value = this.props.value;
    });
  }

  onRef = ref => {
    this.ref && this.ref.removeEventListener('keyup', this.onChange);
    this.ref = ref;
    this.ref && this.ref.addEventListener('keyup', this.onChange);
  }

  render() {
    const isChanged = this.state.value !== this.props.value;

    return (
      this.props.isEnabled && (
        <form onSubmit={this.onSubmit}>
          {this.props.children[0](this.state.value, this.onRef)}

          <button disabled={!isChanged} type="submit">Save</button>
          <button disabled={!isChanged} type="button" onclick={this.onReset}>Reset</button>
        </form>
      ) || this.state.value
    )
  }
}

export default EditText;
