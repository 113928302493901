import { h, Component } from 'preact';
/** @jsx h */

import User from './firebase/user';
import SignInForm from './signInForm';
import SettingsForm from './settingsForm';

import styles from './navigation.scss';

class Navigation extends Component {
  render() {
    const { user, topic, messageOwner } = this.props;

    const isOwner = topic && user && topic.uid === user.uid;

    return (
      <nav class={styles['navigation']}>
        <a title="lookingfor.gg" href="https://lookingfor.gg">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
          </svg>
        </a>
        <span class={styles['spacer']}></span>
        {user && ( // TODO: Add profile button with settings to change display name
          <button title="Account Settings" class={styles['user-settings']} onclick={SettingsForm.open}>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
            </svg>
          </button>
        )}
        <a title="Help" href="/help">
         <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>
        </a>
        {user && topic && topic.uid && !isOwner && (
          <button title="Message Owner" onclick={messageOwner}>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M4,4V17.17L5.17,16H20V4H4M6,7H18V9H6V7M6,11H15V13H6V11Z" />
            </svg>            
          </button>
        )}
        {user && (
          <button title="Sign out" onclick={User.signOut}>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
            </svg>
          </button>
        ) || (
          <button title="Sign in" onclick={SignInForm.signIn}>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z" />
            </svg>
          </button>
        )}
        {user && (
          <SettingsForm user={user} />
        )}
      </nav>
    );
  }
}

export default Navigation;
