import { h, Component } from 'preact';
/** @jsx h */

import Groups from './firebase/groups';
import EditText from './editText';

import styles from './settingsForm.scss';

const providers = [
  {
    name: 'Google',
    class: styles['google'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg',
    provider: new firebase.auth.GoogleAuthProvider(),
  },
  {
    name: 'Facebook',
    class: styles['facebook'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg',
    provider: new firebase.auth.FacebookAuthProvider(),
  },
  {
    name: 'Twitter',
    class: styles['twitter'],
    imageUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg',
    provider: new firebase.auth.TwitterAuthProvider(),
  },
];

const updateDisplayName = async (user, displayName) => {
  await user.updateDisplayName(displayName);

  await Groups.updateDisplayName();
}

class SettingsForm extends Component {
  onRef = ref => {
    SettingsForm.ref = ref;
  }

  static open = () => {
    SettingsForm.ref.showModal();
  }

  render() {
    const user = this.props.user;

    return user && (
      <dialog ref={this.onRef} class={styles['settings']}>
        <form method="dialog">
          <EditText value={user.displayName} onSave={displayName => updateDisplayName(user, displayName)} isEnabled>
            {(value, onRef) => (
              <span>
                Display Name: <input type="text" ref={onRef} value={value} />
              </span>
            )}
          </EditText>
          <ul>
            {providers.map(provider => {
              const isAuthenticated = user.providerData.some(p => p.providerId === provider.provider.providerId);

              const link = e => {
                e.preventDefault();

                user.link(provider.provider);
              };

              const unlink = e => {
                e.preventDefault();

                user.unlink(provider.provider);
              };
              
              return (
                <li class={provider.class} data-authenticated={isAuthenticated}>
                  {isAuthenticated && (
                    <button onclick={unlink}>
                      {provider.imageUrl && (
                        <img src={provider.imageUrl} />
                      )}
                      <span>Unlink from {provider.name}</span>
                    </button>
                  ) || (
                    <button onclick={link}>
                      {provider.imageUrl && (
                        <img src={provider.imageUrl} />
                      )}
                      <span>Link with {provider.name}</span>
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
          <button>Done</button>
        </form>
      </dialog>
    );
  }
}

export default SettingsForm;
