import { Component } from 'preact';

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

class User extends Component {
  constructor() {
    super();

    this.state = {
      user: null,
    };
  }

  static signOut = () => {
    firebase.auth().signOut();

    // TODO: SSO doesn't work!

    // document.cookie = '_auth=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  };

  static signInWithGoogle = async () => {
    const result = await firebase.auth().signInWithPopup(googleProvider);

    console.log(result);

    // TODO: SSO doesn't work!

    // const domain = 'dev.localhost';

    // document.cookie = `_auth=${result.credential.idToken};domain=${domain}`;
  };

  static signInWithFacebook = () => {
    firebase.auth().signInWithPopup(facebookProvider);
  };

  static signInWithTwitter = () => {
    firebase.auth().signInWithPopup(twitterProvider);
  };

  unlink = async provider => {
    const user = await firebase.auth().currentUser.unlink(provider.providerId);

    this.setState({
      user,
    });
  };

  link = async (provider) => {
    const { user } = await firebase.auth().currentUser.linkWithPopup(provider);

    this.setState({
      user,
    });
  };

  // static isUserAuthenticatedWithTwitter = () => firebase.auth().currentUser.providerData.some(p => p.providerId === twitterProvider.providerId);


  componentDidMount() {
    this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
      this.setState({
        user,
      });
    });

    // TODO: SSO doesn't work!

    // const token = getCookie('_auth');

    // if (token) {
    //   firebase.auth().signInWithCustomToken(token);
    // }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // TODO: Implement updateProfile
  updateDisplayName = async displayName => {
    await firebase.auth().currentUser.updateProfile({
      displayName,
    });

    const user = firebase.auth().currentUser;

    this.setState(user);
  }

  render() {
    const { user } = this.state;

    if (user) {
      return this.props.children[0]({
        ...user,
        link: this.link,
        unlink: this.unlink,
        updateDisplayName: this.updateDisplayName,
      });
    }

    return this.props.children[0](null);
  }
}

export default User;
