import { h, Component } from 'preact';
/** @jsx h */

// import styles from './topicSearch.scss';

// const debounce = (func, wait, immediate = false) => {
// 	var timeout;
// 	return function() {
// 		var context = this, args = arguments;
// 		var later = function() {
// 			timeout = null;
// 			if (!immediate) func.apply(context, args);
// 		};
// 		var callNow = immediate && !timeout;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, wait);
// 		if (callNow) func.apply(context, args);
// 	};
// };

class TopicSearch extends Component {
  constructor() {
    super();

    this.state = {
      topics: [],
    };
  }

  // TODO: debounce?
  onChange = async () => {
    this.setState({
      topics: [],
    }, async () => {
      if (this.ref && this.ref.value) {
        // TODO: Move to Topic class?
        const result = await firebase.firestore().collection('topics').where('keywords', 'array-contains', this.ref.value.toLowerCase()).get();

        const topics = result.docs.map(doc => {
          return {
            key: doc.id,
            ...doc.data()
          };
        });
  
        this.setState({
          topics,
        });
      }
    });
  }

  onRef = ref => {
    this.ref = ref;
  }

  render() {
    const query = this.ref && this.ref.value;

    return (
      <section>
        <input type="text" placeholder="What do you want to play?" ref={this.onRef} onkeyup={this.onChange} />
        <br />
        {this.state.topics.length > 0 && (
          <ul>
            {this.state.topics.map(topic => {
              const url = `//${topic.key}.${window.location.host}`;

              return (
                <li>
                  <span>{topic.groupCount || 0} gamers in</span>
                  &nbsp;
                  <a href={url}>{topic.title}</a>
                </li>
              );
            })}
          </ul>
        )}
        {query && this.state.topics.length === 0 && (
          <section>
            <br />
            There's no page for <strong>{this.ref.value}</strong>, but you can make one!
            <br />
            <br />
            <a href={`//${query}.${window.location.host}`}>Click here</a>
          </section>
        )}
      </section>
    );
  }
}

export default TopicSearch;
