import { Component } from 'preact';

import { readFileSync } from 'fs';

import defaultTopic from '../../data/default.json';
import defaultCss from '../../data/default.css.txt';

const protectedTopics = [
  '',
  'lookingfor',
  'localhost',
  'dev',
];

const getKey = () => {
  const key = window.location.hostname.split('.')[0];

  if (protectedTopics.includes(key)) {
    return null;
  }

  return key;
};

class Topic extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentWillMount() {
    const key = getKey();

    if (key) {
      firebase.firestore().collection('topics').doc(key).get().then(doc => {
        if (!doc.exists) {
          this.setState({
            key,
          });

          return;
        }

        const topic = {
          properties: [],
          filters: [],
          post: [],
          ...doc.data(),
        };
        
        const requiredFilters = topic.filters.filter(filter => filter.required === true).map(filter => filter.key);
        
        const optionalFilters = topic.filters.filter(filter => filter.required !== true).map(filter => filter.key);
        
        this.setState({
          ...topic,
          key,
          requiredFilters,
          optionalFilters,
        });
      });
    }
  }

  static isAvailable(key) {
    return new Promise(resolve => {
      firebase.firestore().collection('topics').doc(key).get().then(doc => {
        resolve(!doc.exists);
      });
    });
  }

  static async claimTopic(key) {
    const user = firebase.auth().currentUser;

    const response = await fetch(defaultCss);
    const css = await response.text();

    return firebase.firestore().collection('topics').doc(key).set({
      ...defaultTopic,
      css,
      uid: user.uid,
    });
  }

  static updateTopic(key, field, value) {
    return firebase.firestore().collection('topics').doc(key).update(field, value);
  }

  preview = topic => {
    this.setState({
      ...this.state,
      ...topic,
    });
  }

  render() {
    return this.props.children[0](this.state);
  }
}

export default Topic;
