import { Component } from 'preact';

const getRef = (topic, filters) => {
  const ref = Object.keys(filters).reduce((accumulator, key) => {
    const value = filters[key];

    if (value) {
      accumulator = accumulator.where(key, '==', value);
    }

    return accumulator;
    // TODO: Move these to filters?
  }, firebase.firestore().collection('groups').where('topic', '==', topic).where('active', '==', true));

  return ref.orderBy('time', 'desc').limit(100);
};

class Groups extends Component {
  constructor() {
    super();

    this.state = {
      groups: [],
    };
  }

  componentWillMount() {
    const ref = getRef(this.props.topic, this.props.filters);

    if (!!ref) {
      this.unsubscribe = ref.onSnapshot(snapshot => {
        const groups = snapshot.docs.map(doc => {
          const data = doc.data();

          return {
            uid: doc.id,
            ...data,
            time: data.time && new Date(data.time.toMillis()),
          };
        });

        this.setState({
          groups,
        });
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  static async createGroup(topic, group) {
    const sanitizedGroup = Object.keys(group).reduce((accumulator, key) => {
      const value = group[key];

      if (value) {
        accumulator[key] = value;
      }

      return accumulator;
    }, {});

    const user = firebase.auth().currentUser;

    return firebase.firestore().collection('groups').doc(user.uid).set({
      ...sanitizedGroup,
      topic,
      active: true,
      displayName: user.displayName,
      time: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  static async removeGroup() {
    const user = firebase.auth().currentUser;

    return firebase.firestore().collection('groups').doc(user.uid).set({
      active: false,
    }, {
      merge: true,
    });
  }

  static async updateDisplayName() {
    const user = firebase.auth().currentUser;

    const doc = await firebase.firestore().collection('groups').doc(user.uid).get();

    if (doc.exists) {
      return firebase.firestore().collection('groups').doc(user.uid).set({
        displayName: user.displayName,
      }, {
        merge: true,
      });
    }
  }

  render() {
    return this.props.children[0](this.state.groups);
  }
}

export default Groups;
